<template>
  <div class="toast" v-if="visible">
    <div v-if="status === 'success'" class="toast-content">
      <img src="@/assets/confetti.svg" alt="icon" class="toast-icon" />
      <span>{{ message }}</span>
    </div>
    <div v-if="status === 'error'" class="toast-error">
      <span>{{ message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 20000, // 20 seconds
    },
    status: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.visible = false;
    }, this.duration);
  },
};
</script>

<style scoped>
.toast {
  background-color: #e6f7e6;
  color: #000000;
  padding: 10px 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 400px;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  transition: opacity 0.5s ease-out;
}

.toast-icon {
  margin-right: 10px;
}

.toast-content {
  display: flex;
  align-items: center;
}

.toast-error {
  background-color: var(--state-error);
  color: var(--state-white);
  padding: 10px 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 400px;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  transition: opacity 0.5s ease-out;
}
</style>
