<template>
  <div class="caregiver-container">
    <div style="margin-bottom: 32px">
      <h1>Where do you want to work?</h1>
    </div>
    <div class="selects-div">
          <!-- <SelectDropdown
        label="State"
        id="state-select"
        width="153px"
        :options="stateOptions"
        @update:selectedOption="handleStateSelection"
      /> -->
      <SelectDropdown
        label="State"
        id="state-select"
        width="153px"
        :options="stateOptions"
        :disabled="true"
        :selectedOption="selectedState"
      />
      <SelectDropdown
        label="Lga"
        id="lga-select"
        :options="lgaOptions"
        :selectedOption="selectedLga"
        width="231px"
        @update:selectedOption="handleLgaSelection"
      />
    </div>
 
    <div>
      <BaseButton
        label="Next"
        :disabled="disabled"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
import { ref, inject, watch, onMounted } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import SelectDropdown from "@/components/SelectDropdown.vue";
import statesData from "@/data/states.js";
import { useRouter } from "vue-router";

export default {
 name: "CareSeekerCreateJobStepFour",
  components: {
    BaseButton,
    SelectDropdown,
  },
  setup() {
        const formData = inject("onboardingCareSeekerformData");
    const updateFormData = inject("onboardingUpdateCareSeekerformData");
    const router = useRouter();

         if (formData.value.stepFourData.type_of_care == "" || !formData.value.stepFourData.type_of_care){
      router.push("/careseeker-onboarding/job-post/step-1");
    }
    const localData = ref({
      ...formData.value.stepSevenData,
    });
    const stateOptions = statesData.states
      .map((state) => ({
        value: state.state,
        text: state.state,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));

    const lgaOptions = ref([]);
    const selectedState = ref(localData.value.state || "Lagos"); // Set default selected state to "Lagos" if not set
    const selectedLga = ref(localData.value.lga);
    const disabled = ref(true);

    const handleStateSelection = (value) => {
      selectedState.value = value;
      localData.value.state = value;
      selectedLga.value = "";
      localData.value.local_government = "";
      updateLgaOptions(value);
    };

    const handleLgaSelection = (value) => {
      localData.value.state = "Lagos";
      selectedLga.value = value;
      localData.value.local_government = value;
    };

    const updateLgaOptions = (state) => {
      const selectedStateData = statesData.states.find((s) => s.state === state);
      lgaOptions.value = selectedStateData
        ? selectedStateData.lgas.map((lga) => ({ value: lga, text: lga }))
        : [];
    };
    const handleClick = () => {
     updateFormData("stepSevenData", localData.value);
        router.push("/careseeker-onboarding/job-post/step-5");
        console.log("form data here", formData);
    };

    watch(
      [selectedState, selectedLga],
      ([newSelectedState, newSelectedLga]) => {
        disabled.value = !newSelectedState || !newSelectedLga 
      },
      { immediate: true }
    );

    onMounted(() => {
      updateLgaOptions(selectedState.value); // Auto-populate LGA dropdown
    });

    return {
      stateOptions,
      lgaOptions,
      selectedState,
      selectedLga,

      handleStateSelection,
      handleLgaSelection,
      handleClick,
      disabled,
   
    };
  },
};
</script>

<style scoped>
h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  margin: 0px;
  text-align: center;
}

.text {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: center;
}

.caregiver-container {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.care-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  transition: background-color 0.3s, border-color 0.3s;
}

.care-card-selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal-active);
}

.care-card-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
  margin-bottom: 24px;
  cursor: pointer;
}

.care-card p {
  color: #0d4a34;
}

.selects-div {
  display: flex;
  gap: 16px;
  width: 400px;
  margin-bottom: 20px
}
.work-preference p{
font-size: 16px;
font-weight: 700;
line-height: 27.2px;
letter-spacing: 0.5px;
text-align: left;
color:var(--s-green-darker)
}
.work-preference{
    display: flex;
    justify-content: flex-start;
     width: 400px;
       margin-top: 24px;
       
}
</style>
