import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '@/views/Home/LandingPage.vue';
import LoginPage from '@/views/Login/LoginPage.vue';
import ForgotPassword from '@/views/Login/ForgotPassword.vue';
import ResetPassword from '@/views/Login/ResetPassword.vue';
import OnboardingPage from '@/views/Onboarding/OnboardingPage.vue';
import CareGiverOnboarding from '@/views/Onboarding/CareGiver/IndexPage.vue';
// import BuildProfileOnboarding from '../views/Onboarding/CareGiver/BuildProfile/IndexPage.vue';
import CareSeekerOnboarding from '@/views/Onboarding/CareSeeker/IndexPage.vue';
// import CareSeekerOnboardingCreateJob from '../views/Onboarding/CareSeeker/CreateJob/IndexPage.vue';
import CareGiverStepOne from '@/views/Onboarding/CareGiver/CreateAccount/Steps/StepOne.vue';
import CareGiverStepTwo from '@/views/Onboarding/CareGiver/CreateAccount/Steps/StepTwo.vue';
import CareGiverStepThree from '@/views/Onboarding/CareGiver/CreateAccount/Steps/StepThree.vue';
// import CareGiverStepFour from '../views/Onboarding/CareGiver/CreateAccount/Steps/StepFour.vue';
import StepOne from '@/views/Onboarding/CareGiver/BuildProfile/Steps/StepOne.vue';
import StepTwo from '@/views/Onboarding/CareGiver/BuildProfile/Steps/StepTwo.vue';
import StepThree from '@/views/Onboarding/CareGiver/BuildProfile/Steps/StepThree.vue';
import StepFour from '@/views/Onboarding/CareGiver/BuildProfile/Steps/StepFour.vue';
import CareSeekerStepOne from '@/views/Onboarding/CareSeeker/CreateAccount/Steps/StepOne.vue';
import CareSeekerStepTwo from '@/views/Onboarding/CareSeeker/CreateAccount/Steps/StepTwo.vue';
import CareSeekerStepThree from '@/views/Onboarding/CareSeeker/CreateAccount/Steps/StepThree.vue';
// import CareSeekerStepFour from '../views/Onboarding/CareSeeker/CreateAccount/Steps/StepFour.vue';
// import CareSeekerStepFive from '../views/Onboarding/CareSeeker/CreateAccount/Steps/StepFive.vue';
// import CareSeekerStepSix from '../views/Onboarding/CareSeeker/CreateAccount/Steps/StepSix.vue';
// import CareSeekerStepSeven from '../views/Onboarding/CareSeeker/CreateAccount/Steps/StepSeven.vue';
// import CareSeekerStepEight from '../views/Onboarding/CareSeeker/CreateAccount/Steps/StepEight.vue';
import CareSeekerJobPost from '@/views/Onboarding/CareSeeker/CreateAccount/Steps/JobPost.vue';
import CompleteVerification from '@/views/Onboarding/CareGiver/CompleteVerification/IndexPage';
import CompleteVerificationStepTwo from '@/views/Onboarding/CareGiver/CompleteVerification/StepTwo';
import CaregiverHome from '@/views/Dashboard/Caregiver/HomePage.vue';
import CareseekerHome from '@/views/Dashboard/CareSeeker/HomePage.vue';
import CareSeekerCreateJobStepOne from '@/views/Onboarding/CareSeeker/CreateJob/Steps/StepOne.vue';
 import CareSeekerCreateJobStepTwo from '@/views/Onboarding/CareSeeker/CreateJob/Steps/StepTwo.vue';
import CareSeekerCreateJobStepThree from '@/views/Onboarding/CareSeeker/CreateJob/Steps/StepThree.vue';
import CareSeekerCreateJobStepFour from '@/views/Onboarding/CareSeeker/CreateJob/Steps/StepFour.vue';
import CareSeekerCreateJobStepFive from '@/views/Onboarding/CareSeeker/CreateJob/Steps/StepFive.vue';
import CareSeekerCreateJobStepSix from '@/views/Onboarding/CareSeeker/CreateJob/Steps/StepSix.vue';
import CareSeekerCreateJobStepSeven from '@/views/Onboarding/CareSeeker/CreateJob/Steps/StepSeven.vue';
import JobApplication from '@/views/Dashboard/Caregiver/JobApplication.vue';
// import CaregiverLayout from '@/views/Dashboard/Caregiver/IndexPage.vue';
// import CareSeekerLayout from '@/views/Dashboard/CareSeeker/IndexPage.vue';
import MyJobs from "@/views/Dashboard/Caregiver/MyJobs.vue";
import ProfilePage from "@/views/Dashboard/Caregiver/ProfilePage.vue";
import AccountPage from "@/views/Dashboard/Caregiver/AccountPage.vue";
import AccountCareSeekerPage from "@/views/Dashboard/CareSeeker/AccountPage.vue";
import MessagesPage from "@/views/Dashboard/Caregiver/MessagesPage.vue";
import CareGiverProfile from "@/views/Dashboard/CareSeeker/CaregiverProfile.vue";
import CareSeekerJobs from "@/views/Dashboard/CareSeeker/CareSeekerJobs.vue";
import ViewEditJob from "@/views/Dashboard/CareSeeker/ViewEditJob.vue";
import ViewHire from "@/views/Dashboard/CareSeeker/ViewHire.vue";
import CareSeekerCreateJob from "@/views/Dashboard/CareSeeker/CreateJob.vue";
import CareSeekerEditJob from "@/views/Dashboard/CareSeeker/EditJob.vue";
import CareSeekerProfilePage from "@/views/Dashboard/CareSeeker/ProfilePage.vue";
import AdminLayout from "@/views/Dashboard/Admin/IndexPage.vue";
import AdminGeneral from "@/views/Dashboard/Admin/AdminGeneral.vue";
import AdminCareGivers from "@/views/Dashboard/Admin/AdminCareGivers.vue";
import AdminCareSeekers from "@/views/Dashboard/Admin/AdminCareSeekers.vue";
import AdminReports from "@/views/Dashboard/Admin/AdminReports.vue";
import AdminVerification from "@/views/Dashboard/Admin/AdminVerification.vue";
import AdminList from "@/views/Dashboard/Admin/AdminList.vue";
import AdminTerminationRequests from "@/views/Dashboard/Admin/AdminTerminationRequests.vue";
import VerifyEmail from "@/views/Login/VerifyEmail.vue";
import { getUserRole, isAuthenticated } from '@/services/authentication';
import LandingWaitlist from "@/views/Home/LandingWaitList.vue";
import AboutUs from "@/views/Home/AboutUs.vue";
import FAQ from "@/views/Home/FAQPage.vue";
import PrivacyPolicy from "@/views/Home/PrivacyPolicy.vue";
import TermsOfUse from "@/views/Home/TermsOfUse.vue";
import Payment from "@/views/Dashboard/CareSeeker/PaymentPage.vue";
import PaymentStatus from "@/views/Dashboard/CareSeeker/PaymentStatus.vue";
import CareSeekerHired from "@/views/Dashboard/CareSeeker/CareSeekerHired.vue"
// import { isAuthenticated, getUserRole } from '../services/auth';

const routes = [
  { path: '/', name: 'LandingPage', component: LandingPage },
  { path: '/wait-list', name: 'LandingWaitlist', component: LandingWaitlist },
  { path: '/faq', name: 'FAQPage', component: FAQ },
  { path: '/terms-of-use', name: 'TermsOfUse', component: TermsOfUse },
  { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy },
  { path: '/login', name: 'LoginPage', component: LoginPage },
  { path: '/onboarding', name: 'OnboardingPage', component: OnboardingPage },
  { path: '/about-us', name: 'AboutUs', component: AboutUs },
  { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword },
  { path: '/reset-password', name: 'ResetPassword', component: ResetPassword },
  // { path: '/verify-email', name: 'VerifyEmail', component: VerifyEmail },
  { path: '/verify_email', name: 'VerifyEmail', component: VerifyEmail },

  // { path: '/complete-verification', name: 'CompleteVerification', component: CompleteVerification, meta: { requiresAuth: true, role: 'Care giver' } },
  { path: '/job-post', name: 'CareSeekerJobPost', component: CareSeekerJobPost, meta: { requiresAuth: true, role: 'Care seeker' } },
  {
    path: '/caregiver',
    // component: CaregiverLayout,
    children: [
      { path: '', redirect: '/caregiver/home' },
      { path: 'home', name:"CareGiverHome",component: CaregiverHome, meta: { requiresAuth: true, role: 'Care giver' } },
      { path: 'job-application', name: 'JobApplication', component: JobApplication, meta: { requiresAuth: true, role: 'Care giver' } },
      { path: 'my-jobs', name: 'MyJobs', component: MyJobs, meta: { requiresAuth: true, role: 'Care giver' } },
      { path: 'profile', name: 'ProfilePage', component: ProfilePage, meta: { requiresAuth: true, role: 'Care giver' } },
      { path: 'account-privacy', name: 'AccountPage', component: AccountPage, meta: { requiresAuth: true, role: 'Care giver' } },
      { path: 'messages', name: 'MessagesCaregiverPage', component: MessagesPage, meta: { requiresAuth: true, role: 'Care giver' } },
      // { path: 'step-two', name: 'CaregiverStepTwo', component: CareGiverStepTwo },
      // { path: 'step-three', name: 'CaregiverStepThree', component: CareGiverStepThree },
      // { path: 'step-four', name: 'CaregiverStepFour', component: CareGiverStepFour },
    ]
  },
  {
    path: '/careseeker',
    // component: CareSeekerLayout,
    children: [
      { path: '', redirect: '/careseeker/home' },
      { path: 'messages', name: 'MessagesPage', component: MessagesPage, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'home', name:"CareSeekerHome",component: CareseekerHome, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'caregiver-profile/:id', name:"CareGiverProfile",component: CareGiverProfile, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'my-jobs', name:"CareSeekerJobs", component: CareSeekerJobs, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'hired', name:"CareSeekerHired", component: CareSeekerHired, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'create-job', name:"CreateJob", component: CareSeekerCreateJob, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'profile', name:"CareSeekerProfilePage", component: CareSeekerProfilePage, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'account-privacy', name: 'AccountCareSeekerPage', component: AccountCareSeekerPage, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'job/edit/:jobId',name: 'EditJob',component: CareSeekerEditJob,props: true, meta: { requiresAuth: true, role: 'Care seeker' }},
      { path: 'view-edit-job/:id', name: 'ViewEditJob', component: ViewEditJob,props: true, meta: { requiresAuth: true, role: 'Care seeker' }},
      { path: 'payment/:id?', name: 'PaymentPage', component: Payment,props: true, meta: { requiresAuth: true, role: 'Care seeker' }},
      { path: 'view-hire/:id?', name: 'ViewHire', component: ViewHire,props: true, meta: { requiresAuth: true, role: 'Care seeker' }},
      { path: 'payment-status', name: 'PaymentStatus', component: PaymentStatus,props: true, meta: { requiresAuth: true, role: 'Care seeker' }},
 
      // { path: 'step-two', name: 'CaregiverStepTwo', component: CareGiverStepTwo },
      // { path: 'step-three', name: 'CaregiverStepThree', component: CareGiverStepThree },
      // { path: 'step-four', name: 'CaregiverStepFour', component: CareGiverStepFour },
    ]
  },
  
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      { path: '', redirect: '/admin/general' },
      // { path: 'home', name:"AdminHome",component: AdminHome },
      { path: 'general', name:"AdminGeneral",component: AdminGeneral,  meta: { requiresAuth: true, role: 'Admin' }},
      { path: 'careseekers', name:"AdminCareSeekers",component: AdminCareSeekers,meta: { requiresAuth: true, role: 'Admin' }},
      { path: 'caregivers', name:"AdminCareGivers",component: AdminCareGivers,meta: { requiresAuth: true, role: 'Admin' } },
      { path: 'reports', name:"AdminReports",component: AdminReports, meta: { requiresAuth: true, role: 'Admin' } },
      { path: 'verification', name:"AdminVerification",component: AdminVerification, meta: { requiresAuth: true, role: 'Admin' } },
      
      { path: 'list', name:"AdminList",component: AdminList, meta: { requiresAuth: true, role: 'Admin' } },
      { path: 'termination-requests', name:"AdminTerminationRequests",component: AdminTerminationRequests, meta: { requiresAuth: true, role: 'Admin' } },
    ]
  },

  {
    path: '/caregiver-onboarding',
    component: CareGiverOnboarding,
    children: [
      { path: '', redirect: '/caregiver-onboarding/create-account/step-1' },
      { path: 'create-account/step-1', name: 'CaregiverStepOne', component: CareGiverStepOne,   meta: { requiresAuth: true, role: 'Care giver' } },
      { path: 'create-account/step-2', name: 'CaregiverStepTwo', component: CareGiverStepTwo,   meta: { requiresAuth: true, role: 'Care giver' } },
      { path: 'create-account/step-3', name: 'CaregiverStepThree', component: CareGiverStepThree,    meta: { requiresAuth: true, role: 'Care giver' }},
      { path: 'build-profile/step-1', name: 'BuildProfileStepOne', component: StepOne,   meta: { requiresAuth: true, role: 'Care giver' }},
      { path: 'build-profile/step-2', name: 'BuildProfileStepTwo', component: StepTwo,   meta: { requiresAuth: true, role: 'Care giver' }},
      { path: 'build-profile/step-3', name: 'BuildProfileStepThree', component: StepThree,   meta: { requiresAuth: true, role: 'Care giver' }},
      { path: 'build-profile/step-4', name: 'BuildProfileStepFour', component: StepFour,  meta: { requiresAuth: true, role: 'Care giver' }},
      { path: 'complete-verification/step-1', name: 'CompleteVerification', component: CompleteVerification,  meta: { requiresAuth: true, role: 'Care giver' }},
      { path: 'complete-verification/step-2', name: 'CompleteVerificationStepTwo', component: CompleteVerificationStepTwo,  meta: { requiresAuth: true, role: 'Care giver' }},
      // { path: 'step-four', name: 'CaregiverStepFour', component: CareGiverStepFour },
    ]
  },
  
  {
    path: '/careseeker-onboarding',
    component: CareSeekerOnboarding,
    children: [
      { path: '', redirect: 'careseeker-onboarding/create-account/step-1' },
      { path: 'create-account/step-1', name: 'CareSeekerCreateAccountStepOne', component: CareSeekerStepOne, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'create-account/step-2', name: 'CareSeekerCreateAccountStepTwo', component: CareSeekerStepTwo, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'create-account/step-3', name: 'CareSeekerCreateAccountStepThree', component: CareSeekerStepThree, meta: { requiresAuth: true, role: 'Care seeker' } },
   
      { path: 'job-post/step-1', name: 'CareSeekerCreateJobStepOne', component: CareSeekerCreateJobStepOne, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'job-post/step-2', name: 'CareSeekerCreateJobStepTwo', component: CareSeekerCreateJobStepTwo, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'job-post/step-3', name: 'CareSeekerCreateJobStepThree', component: CareSeekerCreateJobStepThree, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'job-post/step-4', name: 'CareSeekerCreateJobStepFour', component: CareSeekerCreateJobStepFour, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'job-post/step-5', name: 'CareSeekerCreateJobStepFive', component: CareSeekerCreateJobStepFive, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'job-post/step-6', name: 'CareSeekerCreateJobStepSix', component: CareSeekerCreateJobStepSix, meta: { requiresAuth: true, role: 'Care seeker' } },
      { path: 'job-post/step-7', name: 'CareSeekerCreateJobStepSeven', component: CareSeekerCreateJobStepSeven, meta: { requiresAuth: true, role: 'Care seeker' } },
    ]
  },
  

  // Add more routes as necessary
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// using this as a route guard to check authentication and roles if any route is called that needs a role or authentication to access
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Succour Care';
  console.log("Navigating to:", to);
  console.log("to.meta:", to.meta);
  console.log("to.meta.role:", to.meta?.role);
  console.log("User role:", getUserRole());

  if (to.meta.requiresAuth && !isAuthenticated()) {
    // next({ name: 'LoginPage' });
  } else if (to.meta.role && to.meta.role !== getUserRole()) {
    // next({ name: 'LandingPage' });
  } else {
    next();
  }
});


export default router;
